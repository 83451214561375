import React, { Component } from "react";
import { connect } from "react-redux";
import { Form } from "@ant-design/compatible";
import { Button, Input, Col, Row, Drawer, Space, TreeSelect, AutoComplete, Divider, Tag, Tabs } from "antd";
import BaseRadios from '../../components/Elements/BaseRadios';
import UploadImage from '../../components/UploadImage';
import { NotificationManager } from 'react-notifications';
import { isMobile } from 'react-device-detect';
import { makeTree, convertToSlug, recordStatus, processDataImageUpload, processSingleImageUpload } from '../../utils/helpers';
import { createdProduct, updateProduct } from "../../redux/actions/ProductsAction";
import { getAllTags } from '../../redux/actions/TagsAction';
import IntlMessage from "../../components/IntlMessage";
import { getCategoriesActive } from '../../redux/actions/ProductCategoriesAction';
import appConfing from '../../config';
import CKEditorComponent from '../../components/Editor/CKEditorComponent'
import ListCurrentImage from "../../components/ListCurrentImage";
import VariantsList from "./VariantsList";
import CustomOptions from "./CustomOptions";

class ProductForm extends Component {
    constructor() {
        super();
        this.state = {
            isLoading: false,
            isResetUpload: false,
            inputTagValue: "",
            filteredTags: [],
            selectedTags: [],
            tempTags: [],
            thumbnail: [],
            gallery: [],
            currentThumbnail: [],
            currentGallery: [],
            variants: [],
            options: [],
            attribute_groups: []
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {

        if (nextProps.currentData != this.props.currentData) {
            if (nextProps?.currentData?.thumbnail && Array.isArray(nextProps?.currentData?.thumbnail)) {
                this.setState({ currentThumbnail: nextProps?.currentData?.thumbnail })
            } else {
                this.setState({ currentThumbnail: [] })
            }
            if (nextProps?.currentData?.gallery && Array.isArray(nextProps?.currentData?.gallery)) {
                this.setState({ currentGallery: nextProps?.currentData?.gallery })
            } else {
                this.setState({ currentGallery: [] })
            }
            if (nextProps?.currentData?.tags_name && Array.isArray(nextProps?.currentData?.tags_name)) {
                this.setState({ selectedTags: nextProps?.currentData?.tags_name })
            } else {
                this.setState({ selectedTags: [] })
            }
        }
    }

    async handleSubmit(e) {
        e.preventDefault();
        this.props.form.validateFields(async (err, values) => {
            if (!err) {
                delete values.tags
                delete values.thumbnail;
                delete values.gallery;

                this.setState({ isLoading: true });
                const { thumbnail, gallery, currentThumbnail, currentGallery, selectedTags, variants, attribute_groups, options } = this.state;
                try {
                    values.thumbnail = processSingleImageUpload(thumbnail, currentThumbnail);
                    values.gallery = processDataImageUpload(gallery, currentGallery);
                    values.tags = selectedTags;
                    values.variants = variants;
                    values.attribute_groups = attribute_groups;
                    values.options = options;
                    console.log(values)
                    if (this.props.currentData) {
                        await this.props.updateProduct(this.props.currentData.id, values).then(res => {
                            this.props.reloadData();
                            this.setState({
                                isLoading: false,
                                isResetUpload: true
                            });
                        });
                    } else {
                        await this.props.createdProduct(values).then(res => {
                            this.props.reloadData();
                            this.onClose();
                        });
                    }


                } catch (error) {
                    this.setState({ isLoading: false });
                }
            }
        });
    }

    onClose() {
        this.setState({
            isLoading: false,
            isResetUpload: true,
            inputTagValue: "",
            filteredTags: [],
            selectedTags: [],
            tempTags: [],
            thumbnail: [],
            gallery: [],
            variants: [],
            options: [],
            attribute_groups: []
        });
        this.props.form.resetFields();
        this.props.onCancel();
    }

    createSlug(e) {
        var slug = convertToSlug(e.target.value);
        this.props.form.setFieldsValue({ slug: slug });
    }

    handleInputTagChange = async (inputValue) => {
        this.setState({ inputTagValue: inputValue });
        // Lấy từ cuối cùng sau dấu phẩy
        const keywords = inputValue.split(',');
        const lastKeyword = keywords[keywords.length - 1].trim();
        this.props.getAllTags({ keyword: lastKeyword }).then(res => {
            const { tempTags } = this.state;
            const filteredTags = res.data.data.filter(tag => !tempTags.includes(tag));
            this.setState({
                filteredTags
            });
        });
    };

    handleTagSelect = (value) => {
        const { tempTags } = this.state;
        const newTag = value.trim();

        if (newTag && !tempTags.includes(newTag)) {
            const updatedTags = [...tempTags, newTag];
            const updatedInputValue = updatedTags.join(', ') + ', ';
            this.setState({
                tempTags: updatedTags,
                inputTagValue: updatedInputValue,
                filteredTags: []
            });
        } else {
            const updatedInputValue = tempTags.join(', ') + ', ';
            this.setState({
                inputTagValue: updatedInputValue
            });
        }
    };

    handleAddTag = () => {
        const { inputTagValue, selectedTags } = this.state;
        const newTags = inputTagValue.split(',').map(tag => tag.trim()).filter(tag => tag !== '');
        const updatedSelectedTags = [...selectedTags, ...newTags];
        // Lọc trùng lặp trong danh sách selectedTags
        const uniqueSelectedTags = [...new Set(updatedSelectedTags)];
        this.setState({
            selectedTags: uniqueSelectedTags,
            inputTagValue: '',
            filteredTags: [],
            tempTags: []
        });
    };

    renderSelectedTags = () => {
        const { selectedTags } = this.state;
        return selectedTags.map((tag, index) => (
            <Tag
                className="edit-tag mb-1"
                key={tag}
                closable={true}
                color="cyan"
                onClose={() => this.handleClose(tag)}
            >
                {tag}
            </Tag>
        ));
    }

    handleClose = (removedTag) => {
        const { selectedTags } = this.state;
        const updatedTags = selectedTags.filter(tag => tag !== removedTag);
        this.setState({
            selectedTags: updatedTags
        });
    }

    onChangeThumbnail = (data) => {
        this.setState({
            isResetUpload: false,
            thumbnail: data
        });
        NotificationManager.success("Thumbnail complete.");
    }

    getThumbnail(data) {
        this.setState({ currentThumbnail: data })
    }

    onChangeGallery = (data) => {
        this.setState({
            isResetUpload: false,
            gallery: data
        });
        NotificationManager.success("Gallery complete.");
    }

    getGallery(data) {
        this.setState({ currentGallery: data })
    }

    handleDataFromChild = (data) => {
        const { variants, group_ids } = data.data;
        this.setState({ variants, attribute_groups: group_ids });
    };

    // handleDataFromChild = (data) => {
    //     this.setState({ variants: data });
    // };

    handleDataOption = (data) => {
        //console.log(data)
        this.setState({ options: data });
    }

    render() {
        const { visible, currentData, categories_active_list } = this.props;
        const { isLoading, isResetUpload,
            inputTagValue, filteredTags, currentThumbnail,
            currentGallery } = this.state;
        const { getFieldDecorator } = this.props.form;

        const catTree = makeTree(categories_active_list || [], 0);

        return (
            <>
                <Drawer
                    visible={visible}
                    title={currentData ? <IntlMessage id="title.updateRecord" /> : <IntlMessage id="title.createRecord" />}
                    width={isMobile ? '100%' : '65%'}
                    onClose={() => this.onClose()}
                    maskClosable={false}
                    footer={
                        <div style={{ textAlign: 'right', }}>
                            <Space>
                                <Button key="back" onClick={() => this.onClose()}>
                                    Cancel
                                </Button>
                                <Button
                                    loading={isLoading}
                                    key="submit"
                                    type="primary"
                                    onClick={(e) => this.handleSubmit(e)}
                                >
                                    Save
                                </Button>
                            </Space>
                        </div>
                    }
                >
                    {visible ? (
                        <>
                            <Form layout='vertical' className="custom-tab-product">
                                <Tabs defaultActiveKey="1" type="card">
                                    <Tabs.TabPane tab="General" key="1">
                                        <Form.Item label="Name">
                                            {getFieldDecorator("name", {
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: "Field is required!",
                                                    },
                                                ],
                                                initialValue: currentData ? currentData.name : null
                                            })(<Input
                                                onKeyPress={(e) => this.createSlug(e)}
                                                onBlur={(e) => this.createSlug(e)}
                                            />)}
                                        </Form.Item>
                                        <Form.Item label="Permalink">
                                            {getFieldDecorator("slug", {
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: "Field is required!",
                                                    },
                                                ],
                                                initialValue: currentData ? currentData.slug : null
                                            })(
                                                <Input addonBefore={`${appConfing.BASE_URL}/product/`} addonAfter="/" />
                                            )}
                                        </Form.Item>
                                        <Row gutter={16}>
                                            <Col xs={24} md={8}>
                                                <Form.Item label='Primary categories'>
                                                    {getFieldDecorator("primary_category_id", {
                                                        rules: [
                                                            {
                                                                required: true,
                                                                message: "Vui lòng chọn!",
                                                            },
                                                        ],
                                                        initialValue: currentData ? currentData.primary_category_id : null
                                                    })(
                                                        <TreeSelect
                                                            style={{ width: '100%' }}
                                                            dropdownStyle={{ maxHeight: 450, overflow: 'auto' }}
                                                            treeData={catTree}
                                                            showSearch={true}
                                                            treeNodeFilterProp='title'
                                                            allowClear
                                                            placeholder="Select one categories"
                                                            treeDefaultExpandAll
                                                        />
                                                    )}
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} md={16}>
                                                <Form.Item label='Categories'>
                                                    {getFieldDecorator("categories_ids", {
                                                        rules: [
                                                            {
                                                                required: true,
                                                                message: "Field is required!",
                                                            },
                                                        ],
                                                        initialValue: currentData ? currentData.productCategoryIds : []
                                                    })(
                                                        <TreeSelect
                                                            style={{ width: '100%' }}
                                                            dropdownStyle={{ maxHeight: 450, overflow: 'auto' }}
                                                            treeData={catTree}
                                                            multiple={true}
                                                            showSearch={true}
                                                            treeNodeFilterProp='title'
                                                            allowClear
                                                            placeholder="Select one or more categories"
                                                            treeDefaultExpandAll
                                                        />
                                                    )}
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} md={8}>
                                                <Form.Item label="Regular price ($)">
                                                    {getFieldDecorator("regular_price", {
                                                        rules: [
                                                            {
                                                                required: true,
                                                                message: "Field is required!",
                                                            },
                                                        ],
                                                        initialValue: currentData ? currentData.regular_price : null
                                                    })(
                                                        <Input placeholder='21.95' />
                                                    )}
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} md={8}>
                                                <Form.Item label='Sale price ($)'>
                                                    {getFieldDecorator("sale_price", {
                                                        rules: [
                                                            {
                                                                required: false,
                                                                message: "Field is required!",
                                                            },
                                                        ],
                                                        initialValue: currentData ? currentData.sale_price : null
                                                    })(
                                                        <Input placeholder='15.5' />
                                                    )}
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} md={24}>
                                                <Form.Item label="Select tags">
                                                    {getFieldDecorator('tags', {
                                                        rules: [
                                                            {
                                                                required: false,
                                                                message: 'Please select tags!',
                                                            },
                                                        ],
                                                        initialValue: currentData ? currentData.tags : inputTagValue
                                                    })(
                                                        <div style={{ display: 'flex', width: '100%' }}>
                                                            <AutoComplete
                                                                value={inputTagValue}
                                                                onChange={this.handleInputTagChange}
                                                                onSelect={this.handleTagSelect}
                                                                style={{ flex: 1, marginRight: '8px' }}
                                                                options={filteredTags.map(tag => ({ value: tag.name }))}
                                                                placeholder="Enter tags"
                                                            >
                                                                <Input />
                                                            </AutoComplete>
                                                            <Button
                                                                type="dashed"
                                                                style={{ width: '74px' }}
                                                                onClick={this.handleAddTag}
                                                            >
                                                                Add
                                                            </Button>
                                                        </div>
                                                    )}

                                                    <div style={{ margin: "10px 0 0 0" }}>
                                                        {this.renderSelectedTags()}
                                                    </div>
                                                    <Divider />
                                                </Form.Item>

                                            </Col>
                                        </Row>
                                        <Form.Item label='Status'>
                                            {getFieldDecorator("status", {
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: "Field is required!",
                                                    },
                                                ],
                                                initialValue: currentData ? currentData.status : null
                                            })(
                                                <BaseRadios
                                                    options={recordStatus}
                                                />
                                            )}
                                        </Form.Item>
                                        <Form.Item label="Description">
                                            {getFieldDecorator('description', {
                                                rules: [
                                                    {
                                                        required: false,
                                                        message: "Field is required!",
                                                    },
                                                ],
                                                initialValue: currentData ? currentData.description : null
                                            })(
                                                <CKEditorComponent
                                                    initialHeight="300px"
                                                    editorData={currentData?.description}
                                                />
                                            )}
                                        </Form.Item>
                                        <Row gutter={16}>
                                            <Col xs={24} md={12}>
                                                <Form.Item label="Product Image" className='flex-upload border-dashed p-2'>
                                                    <ListCurrentImage currentImages={currentThumbnail} getData={(data) => this.getThumbnail(data)} />
                                                    {getFieldDecorator('thumbnail', {
                                                        rules: [
                                                            {
                                                                required: false,
                                                                message: 'Field is required!',
                                                            },
                                                        ],
                                                        initialValue: null
                                                    })(
                                                        <UploadImage
                                                            multiple={true}
                                                            maxCount={1}
                                                            onChangeData={this.onChangeThumbnail}
                                                            isResetUpload={isResetUpload}
                                                        />
                                                    )}
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} md={12}>
                                                <Form.Item label="Product gallery" className='flex-upload border-dashed p-2'>
                                                    <ListCurrentImage currentImages={currentGallery} getData={(data) => this.getGallery(data)} />
                                                    {getFieldDecorator('gallery', {
                                                        rules: [
                                                            {
                                                                required: false,
                                                                message: "Field is required!",
                                                            },
                                                        ],
                                                        initialValue: null
                                                    })(
                                                        <UploadImage
                                                            multiple={true}
                                                            maxCount={10}
                                                            onChangeData={this.onChangeGallery}
                                                            isResetUpload={isResetUpload}
                                                        />
                                                    )}
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} md={24} className="mt-3">
                                                <Form.Item label="SEO Title">
                                                    {getFieldDecorator('seo_title', {
                                                        rules: [
                                                            {
                                                                required: false,
                                                                message: 'Field is required!',
                                                            },
                                                        ],
                                                        initialValue: currentData ? currentData.seo_title : null
                                                    })(<Input />)}
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} md={24}>
                                                <Form.Item label="SEO Descriptiom">
                                                    {getFieldDecorator('seo_description', {
                                                        rules: [
                                                            {
                                                                required: false,
                                                                message: 'Field is required!',
                                                            },
                                                        ],
                                                        initialValue: currentData ? currentData.seo_description : null
                                                    })(<Input.TextArea rows={4} />)}
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} md={24}>
                                                <Form.Item label="Canonical Url">
                                                    {getFieldDecorator('canonical_url', {
                                                        rules: [
                                                            {
                                                                required: false,
                                                                message: 'Field is required!',
                                                            },
                                                        ],
                                                        initialValue: currentData ? currentData.canonical_url : null
                                                    })(<Input />)}
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Tabs.TabPane>
                                    {/* 
                                    Tab Variants and options 
                                    Thuộc tính 'forceRender' sẽ render tất cả các tab khi mở form, không cần click vào tab đó
                                    */}
                                    <Tabs.TabPane tab="Variants" key="2" forceRender>
                                        <VariantsList
                                            currentData={currentData}
                                            sendDataToParent={this.handleDataFromChild}
                                        />
                                    </Tabs.TabPane>
                                    <Tabs.TabPane tab="Custom options" key="3" forceRender>
                                        <CustomOptions
                                            currentData={currentData}
                                            sendDataToParent={this.handleDataOption}
                                        />
                                    </Tabs.TabPane>
                                    <Tabs.TabPane tab="Additionally" key="4" forceRender>
                                        <Divider orientation="left" orientationMargin={0}>Collection</Divider>
                                        <Divider orientation="left" orientationMargin={0}>Related products</Divider>
                                    </Tabs.TabPane>
                                </Tabs>
                            </Form>
                        </>
                    ) : null
                    }
                </Drawer>
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        categories_active_list: state.ProductCategories.categories_active_list
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        createdProduct: (data) => dispatch(createdProduct(data)),
        updateProduct: (id, data) => dispatch(updateProduct(id, data)),
        getAllTags: (filter) => dispatch(getAllTags(filter)),
        getCategoriesActive: (filter) => dispatch(getCategoriesActive(filter))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: "ProductForm" })(ProductForm));
