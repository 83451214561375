import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Select, Space, Row, Col, Table, Typography, InputNumber, Switch } from 'antd';
import { CloseOutlined, DragOutlined } from '@ant-design/icons';
import { NotificationManager } from 'react-notifications';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';

const { Title } = Typography;
const { Option } = Select;

// Drag handle component
const DragHandle = SortableHandle(() => (
    <DragOutlined className='item-action-btn-drag' onMouseDown={(e) => e.preventDefault()} />
));
// Function to move an item in an array
const moveItemInArray = (array, fromIndex, toIndex) => {
    const newArray = [...array];
    const [removed] = newArray.splice(fromIndex, 1);
    newArray.splice(toIndex, 0, removed);
    return newArray;
};

// Sortable item component
const SortableItem = SortableElement((props) => <tr {...props} />);

// Sortable body container
const SortableBody = SortableContainer((props) => <tbody {...props} />);


const CustomOptions = ({ sendDataToParent, currentData }) => {
    const [form] = Form.useForm();
    const [formFields, setFormFields] = useState(currentData?.custom_options || []);
    const [fieldType, setFieldType] = useState('');
    const [fieldLabel, setFieldLabel] = useState('');
    const [fieldPlaceholder, setFieldPlaceholder] = useState('');
    const [fieldExample, setFieldExample] = useState('');
    const [entryLimit, setEntryLimit] = useState(1);
    const [dragEnabled, setDragEnabled] = useState(true); // Trạng thái để kiểm soát Drag-and-Drop

    useEffect(() => {
        sendDataToParent(formFields);
    }, [formFields, sendDataToParent]);

    const handleAddFields = () => {
        if (!fieldType) {
            NotificationManager.error('Please select a field type.');
            return;
        }

        if (!fieldLabel) {
            NotificationManager.error('Please enter a field label.');
            return;
        }

        const generatedFieldKey = fieldLabel.toLowerCase().replace(/ /g, '_');

        const isDuplicateKey = formFields.some(field => field.key === generatedFieldKey);

        if (isDuplicateKey) {
            NotificationManager.error('Field key already exists. Please enter a unique field key.');
            return;
        }

        const newFields = [{
            type: fieldType,
            label: fieldLabel,
            placeholder: fieldPlaceholder,
            key: generatedFieldKey,
            entry_limit: entryLimit,
            required: 0,
            example: fieldExample,
        }];

        setFormFields([...formFields, ...newFields]);
        setFieldLabel('');
        setFieldPlaceholder('');
        setEntryLimit(1);
        setFieldExample('');
        sendDataToParent([...formFields, ...newFields]);
    };

    const handleRemoveField = (key) => {
        const updatedFields = formFields.filter(field => field.key !== key);
        setFormFields(updatedFields);
        sendDataToParent(updatedFields);
    };

    const onSortStart = () => {
        console.log('Sorting started...');
        // Add the noselect class to prevent text selection during drag
        document.body.classList.add('noselect');
    };

    const onSortEnd = ({ oldIndex, newIndex }) => {

        // Remove the noselect class after sorting is done
        document.body.classList.remove('noselect');

        if (oldIndex !== newIndex) {
            // Move items in the array based on drag
            const newData = moveItemInArray(formFields.slice(), oldIndex, newIndex);

            // Map over the updated array to assign the new 'order' field
            const updatedData = newData.map((item, index) => ({
                ...item,
                order: index + 1, // Update order based on new index
            }));

            // Update form fields with the reordered data
            setFormFields(updatedData);
            sendDataToParent(updatedData);

            console.log('Sorted items: ', updatedData); // Log the sorted array for debugging
        }
    };

    // Draggable container component
    const DraggableContainer = (props) => (
        <SortableBody
            useDragHandle
            disableAutoscroll
            helperClass="row-dragging"
            onSortStart={onSortStart}
            onSortEnd={onSortEnd}
            {...props}
        />
    );

    // Draggable body row component
    const DraggableBodyRow = ({ className, style, ...restProps }) => {
        const index = formFields.findIndex((x) => x.key === restProps['data-row-key']);
        return <SortableItem index={index} {...restProps} />;
    };

    const handleColumnChange = (key, field, value) => {
        const updatedFields = formFields.map(item =>
            item.key === key ? { ...item, [field]: value } : item
        );
        setFormFields(updatedFields);
        sendDataToParent(updatedFields);
    };

    const handleInputFocus = (event) => {
        setDragEnabled(false); // Vô hiệu hóa Drag-and-Drop khi có ô input đang được chỉnh sửa
        event.target.focus(); // Đảm bảo focus vào ô input
    };

    const handleInputBlur = () => {
        setDragEnabled(true); // Bật lại Drag-and-Drop khi không còn ô input nào đang được chỉnh sửa
    };

    const columns = [
        {
            title: 'Sort',
            dataIndex: 'sort',
            width: 30,
            className: 'drag-visible',
            render: () => <DragHandle />,
        },
        {
            title: 'Field Type',
            dataIndex: 'type',
            key: 'type',
            width: 120,
            className: 'drag-visible',
        },
        {
            title: 'Field Label',
            dataIndex: 'label',
            key: 'label',
            className: 'drag-visible',
            render: (text, record) => (
                <Input
                    placeholder="Enter label"
                    className="w-100"
                    value={record.label}
                    onChange={(e) => handleColumnChange(record.key, 'label', e.target.value)}
                    onFocus={handleInputFocus}
                    onBlur={handleInputBlur}
                />
            )
        },
        {
            title: 'Field Placeholder',
            dataIndex: 'placeholder',
            key: 'placeholder',
            className: 'drag-visible',
            render: (text, record) => (
                <Input
                    placeholder="Enter placeholder"
                    className="w-100"
                    value={record.placeholder}
                    onChange={(e) => handleColumnChange(record.key, 'placeholder', e.target.value)}
                    onFocus={handleInputFocus}
                    onBlur={handleInputBlur}
                />
            )
        },
        {
            title: 'Field Example',
            dataIndex: 'example',
            key: 'example',
            className: 'drag-visible',
            render: (text, record) => (
                record.type !== 'upload' ? (
                    <Input
                        placeholder="Enter example"
                        className="w-100"
                        value={record.example}
                        onChange={(e) => handleColumnChange(record.key, 'example', e.target.value)}
                        onFocus={handleInputFocus}
                        onBlur={handleInputBlur}
                    />
                ) : null
            )
        },
        {
            title: 'Entry Limit',
            dataIndex: 'entry_limit',
            key: 'entry_limit',
            className: 'drag-visible',
            render: (text, record) => (
                <InputNumber
                    min={0}
                    className="w-5"
                    value={record.entry_limit}
                    onChange={(value) => handleColumnChange(record.key, 'entry_limit', value)}
                    onFocus={handleInputFocus}
                    onBlur={handleInputBlur}
                />
            )
        },
        {
            title: 'Required',
            dataIndex: 'required',
            key: 'required',
            className: 'drag-visible',
            render: (text, record) => (
                <Switch
                    size="small"
                    checked={record.required === 1}
                    checkedChildren="Yes"
                    unCheckedChildren="No"
                    onChange={(value) => handleColumnChange(record.key, 'required', value ? 1 : 0)}
                    onFocus={handleInputFocus}
                    onBlur={handleInputBlur}
                />
            )
        },
        {
            title: 'Action',
            key: 'action',
            width: 50,
            align: 'center',
            className: 'drag-visible',
            render: (text, record) => (
                <Space size="middle">
                    <CloseOutlined
                        className='item-action-btn-remove'
                        onClick={() => handleRemoveField(record.key)}
                    />
                </Space>
            ),
        },
    ];

    return (
        <>
            <Form form={form} autoComplete="off" layout='vertical' className='mb-4'>
                <Row gutter={16}>
                    <Col xs={24} md={24}>
                        <Title level={5} className="mt-3 mb-3">Select fields for custom field setup</Title>
                    </Col>
                    <Col xs={24} md={4}>
                        <Form.Item label="Field Type">
                            <Select
                                value={fieldType || undefined}
                                onChange={setFieldType}
                                placeholder="Select field type"
                                className='w-100'
                            >
                                <Option value="upload">Upload Image</Option>
                                <Option value="text">Text Input</Option>
                                <Option value="textarea">Text Area</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={6}>
                        <Form.Item label="Field Label">
                            <Input
                                value={fieldLabel}
                                onChange={(e) => setFieldLabel(e.target.value)}
                                placeholder="Enter field label"
                                className='w-100'
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={6}>
                        <Form.Item label="Field Placeholder">
                            <Input
                                value={fieldPlaceholder}
                                onChange={(e) => setFieldPlaceholder(e.target.value)}
                                placeholder="Enter field placeholder"
                                className='w-100'
                            />
                        </Form.Item>
                    </Col>
                    {fieldType !== 'upload' ? (
                        <Col xs={24} md={6}>
                            <Form.Item label="Field Example">
                                <Input
                                    value={fieldExample}
                                    onChange={(e) => setFieldExample(e.target.value)}
                                    placeholder="Enter field example"
                                    className='w-100'
                                />
                            </Form.Item>
                        </Col>
                    ) : null}
                    <Col xs={24} md={4}>
                        <Form.Item label="Entry Limit">
                            <InputNumber
                                value={entryLimit}
                                onChange={(value) => setEntryLimit(value)}
                                className='w-100'
                                min={1}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={4}>
                        <Form.Item label="&nbsp;">
                            <Button type="primary" onClick={handleAddFields}>
                                Create Fields
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
                <Table
                    size='small'
                    columns={columns}
                    dataSource={formFields}
                    rowKey="key"
                    pagination={false}
                    components={{
                        body: {
                            wrapper: dragEnabled ? DraggableContainer : undefined,
                            row: dragEnabled ? DraggableBodyRow : undefined,
                        },
                    }}
                />
            </Form>
        </>
    );
};

export default CustomOptions;
